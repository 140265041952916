import { Button, Form, Input, Tooltip } from "antd";
import emailjs from "emailjs-com";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Contact.scss";

const ContactPage = ( ) => {
  const { t } = useTranslation();

    return (
      <div id="contact">
        <Header
          isTransparentHeader={false}
          colorfulLinkItem={true}
        />
        <div>
          <div
            className="banner-container d-none d-md-block"
            style={{
              background:
                "url(/assets/images/contact-us/contact-us-banner.png)",
            }}
          ></div>
          <div
            className="banner-container d-md-none"
            style={{
              background:
                "url(/assets/images/contact-us/contact-banner-mobile.png)",
            }}
          ></div>
          <div className="info-box-top">
            <div>
              <span className="title">{t("contactInformation")}</span>
            </div>

            <div className={"d-flex align-items-center"}>
              <img
                className="mr-2"
                src="/assets/images/contact-us/location-mobile.svg"
                alt=""
              />
              <span>
                Telmetal Soğuk Haddecilik Sanayi ve Ticaret A.Ş. Dilovası OSB
                Mah. Gediz Cad. No: 15/1 41400 Gebze/Kocaeli TR
              </span>
            </div>
            <div className={"d-flex align-items-center"}>
              <img
                className="mr-2"
                src="/assets/images/contact-us/phone-mobile.svg"
                alt=""
              />
              <span>+90 262 759 18 00</span>
            </div>
            <div className={"d-flex align-items-center "}>
              <img
                className="mr-2"
                src="/assets/images/contact-us/mail-mobile.svg"
                alt=""
              />
              <a className="mail" href="mailto:info@telmetal.com">
                info@telmetal.com
              </a>
            </div>
          </div>

          <div className="info-box-bottom">
            <div>
              <span className="title">{t("career")}</span>
            </div>
            <div>
              <span>
                <strong>{t("telmetal")}</strong>
                {t("careerDescription")}
              </span>
            </div>
            <div>
              <a href="#scroll" className="d-flex align-items-center">
                <img
                  className="mr-2"
                  src="/assets/images/contact-us/arrow-white.svg"
                  alt=""
                />
                <span className="inspect">{t("review")}</span>
              </a>
            </div>
          </div>

          <div className="info-boxes d-none d-lg-flex">
            <div className="info-box mr-5">
              <div>
                <span className="title">{t("contactInformation")}</span>
              </div>

              <div className={"d-flex align-items-center"}>
                <img
                  className="mr-4"
                  src="/assets/images/contact-us/location.svg"
                  alt=""
                />
                <span>
                  Telmetal Soğuk Haddecilik Sanayi ve Ticaret A.Ş. Dilovası OSB
                  Mah. Gediz Cad. No: 15/1 41400 Gebze/Kocaeli TR
                </span>
              </div>
              <div className={"d-flex align-items-center"}>
                <img
                  className="mr-4"
                  src="/assets/images/contact-us/phone.svg"
                  alt=""
                />
                <span>+90 262 759 18 00</span>
              </div>
              <div className={"d-flex align-items-center "}>
                <img
                  className="mr-4"
                  src="/assets/images/contact-us/mail.svg"
                  alt=""
                />
                <a className="mail" href="mailto:info@telmetal.com">
                  info@telmetal.com
                </a>
              </div>
            </div>

            <div className="info-box">
              <div>
                <span className="title">{t("career")}</span>
              </div>
              <div>
                <span>
                  <strong>{t("telmetal")}</strong>
                  {t("careerDescription")}
                </span>
              </div>
              <div>
                <a href="#scroll" className="d-flex">
                  <img
                    className="mr-4"
                    src="/assets/images/contact-us/arrow-white.svg"
                    alt=""
                  />
                  <span className="inspect"> {t("review")}</span>
                </a>
              </div>
            </div>
          </div>

          <div className="gray-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title"> {t("help")}</div>

                  <Formik
                    initialValues={{
                      nameSurname: "",
                      phoneNumber: "",
                      email: "",
                      yourMessage: "",
                      companyName: "",
                    }}
                    onSubmit={(values) => {
                      emailjs
                        .send(
                          "gmail", //Email service as defined in EmailJS setting
                          "template_a9mqcee", // Email template ID provided by EmailJS
                          {
                            from_name: values.nameSurname,
                            to_name: "",
                            phone_number: values.phoneNumber,
                            email: values.email,
                            message_html: values.yourMessage,
                            company_name: values.companyName,
                            reply_to: values.email,
                          },
                          "user_O1JyUNVpg9BTJv1zvwe20" // EmailJS user ID
                        )
                        .then(
                          (response) => {
                            console.log(
                              "SUCCESS!",
                              response.status,
                              response.text
                            );
                          },
                          (err) => {
                            console.log("FAILED...", err);
                          }
                        );
                    }}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      isValid,
                      resetForm,
                    }) => (
                      <form className="job-form" onSubmit={handleSubmit}>
                        <div className="row justify-content-between text-center">
                          <div className=" col-sm-12 col-md-5">
                            <Tooltip
                              placement="bottomRight"
                              visible={
                                errors.nameSurname && touched.nameSurname
                                  ? true
                                  : false
                              }
                              title={errors.nameSurname}
                            >
                              <Form.Item
                                validateStatus={
                                  errors.nameSurname && touched.nameSurname
                                    ? "error"
                                    : "validating"
                                }
                              >
                                <Input
                                  name="nameSurname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.nameSurname}
                                  className="input"
                                  size="large"
                                  placeholder={t("nameSurname")}
                                />
                              </Form.Item>
                            </Tooltip>

                            <Tooltip
                              placement="bottomRight"
                              visible={
                                errors.email && touched.email ? true : false
                              }
                              title={errors.email}
                            >
                              <Form.Item
                                validateStatus={
                                  errors.email && touched.email
                                    ? "error"
                                    : "validating"
                                }
                              >
                                <Input
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  className="input"
                                  size="large"
                                  placeholder={t("email")}
                                />
                              </Form.Item>
                            </Tooltip>

                            <Tooltip
                              placement="bottomRight"
                              visible={
                                errors.phoneNumber && touched.phoneNumber
                                  ? true
                                  : false
                              }
                              title={errors.phoneNumber}
                            >
                              <Form.Item
                                validateStatus={
                                  errors.phoneNumber && touched.phoneNumber
                                    ? "error"
                                    : "validating"
                                }
                              >
                                <Input
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  className="input"
                                  size="large"
                                  placeholder={t("phoneNumber")}
                                />
                              </Form.Item>
                            </Tooltip>

                            <Tooltip
                              placement="bottomRight"
                              visible={
                                errors.companyName && touched.companyName
                                  ? true
                                  : false
                              }
                              title={errors.companyName}
                            >
                              <Form.Item
                                validateStatus={
                                  errors.companyName && touched.companyName
                                    ? "error"
                                    : "validating"
                                }
                              >
                                <Input
                                  name="companyName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyName}
                                  className="input"
                                  size="large"
                                  placeholder={t("companyName")}
                                />
                              </Form.Item>
                            </Tooltip>
                          </div>
                          <div className=" offset-md-1 col-sm-12 col-md-5">
                            <Tooltip
                              placement="bottomRight"
                              visible={
                                errors.yourMessage && touched.yourMessage
                                  ? true
                                  : false
                              }
                              title={errors.yourMessage}
                            >
                              <Form.Item
                                validateStatus={
                                  errors.yourMessage && touched.yourMessage
                                    ? "error"
                                    : "validating"
                                }
                              >
                                <Input.TextArea
                                  rows={6}
                                  name="yourMessage"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.yourMessage}
                                  className="enquiry-input"
                                  placeholder={t("message")}
                                />
                              </Form.Item>
                            </Tooltip>

                            <div>
                              <Button
                                htmlType="submit"
                                className="submit-button"
                                disabled={!isValid}
                              >
                                <img
                                  className="mr-2 arrow-black"
                                  src="/assets/images/footer/arrow-black.svg"
                                  alt=""
                                />
                                {t("send")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "121px" }} id="scroll"></div>
          <div className="description-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <p className="title">{t("ethicalValues")}</p>
                  <p className="description d-none d-lg-block">
                    <strong> {t("telmetal")}</strong>
                    {t("ethicalValuesDescriptionEN")}
                    {t("ethicalValuesDescriptionTR")}
                    <strong>{t("telmetalAboutUsEN")}</strong>
                    {t("ethicalValuesDescriptionEN2")}
                    <strong>{t("telmetalAboutUsTR")}</strong>
                    {t("ethicalValuesDescriptionTR2")}
                  </p>
                  <p className="description d-lg-none">
                    <strong> {t("telmetal")}</strong>
                    {t("ethicalValuesDescriptionEN")}
                    {t("ethicalValuesDescriptionTR")}
                    <strong>{t("telmetalAboutUsEN")}</strong>
                    {t("ethicalValuesDescriptionEN2")}
                    <strong>{t("telmetalAboutUsTR")}</strong>
                    {t("ethicalValuesDescriptionTR2")}
                  </p>
                </div>
                <div className="col-lg-5 d-none d-lg-block">
                  <img src="/assets/images/contact-us/lab-image1.png" alt="" />
                </div>
              </div>
              <div className="area-margin">
                <div className="row align-items-center">
                  <div className="col-lg-5  d-none d-lg-block">
                    <img
                      src="/assets/images/contact-us/lab-image2.png"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="pl-sm-0 pl-lg-5">
                      <p className="title"> {t("hrPolicy")}</p>
                      <p className="description d-none d-lg-block">
                        <strong> {t("telmetal")}</strong>
                        {t("hrPolicyDescription")}
                      </p>
                      <p className="description d-lg-none">
                        <strong>{t("telmetal")}</strong>
                        {t("hrPolicyDescription")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <img
                className="w-100"
                src="assets/images/contact-us/lab-image3.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <Footer isContactPage={true} />
      </div>
    );
  }

  export default ContactPage;
