import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./About.scss";
const Fade = require("react-reveal/Fade");


const AboutUs = () => {
  const { t } = useTranslation();
  
  const SampleNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="custom-arrows d-none d-md-flex">
        <div className="next-arrow" onClick={onClick}>
          <img
            className=""
            src="/assets/images/about-us/arrow-next-white.svg"
            alt=""
          />
        </div>
      </div>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="custom-arrows d-none d-md-flex">
        <div className="previous-arrow" onClick={onClick}>
          <img
            className=""
            src="/assets/images/about-us/arrow-before-white.svg"
            alt=""
          />
        </div>
      </div>
    );
  };
  var settings = {
    centerMode: true,
    slidesToShow: 1.5,
    speed: 200,
    slidesToScroll: 1.5,
    dots: true,
    items: 1,
    centerPadding: "100px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1.5,
          dots: true,
          items: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1.5,
          items: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1.5,
          items: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1.5,
          dots: false,
          items: 1.5,
        },
      },
    ],
  };

  return (
    <div id="about-us">
      <Header isTransparentHeader={false} colorfulLinkItem={false} />
      <div>
        <div
          className="banner-container d-none d-md-block"
          style={{
            background: "url(/assets/images/about-us/about-us-banner.jpg)",
          }}
        ></div>
        <div
          className="banner-container d-md-none"
          style={{
            background:
              "url(/assets/images/about-us/about-us-banner-mobile.jpg)",
          }}
        ></div>
        <Fade bottom>
          <div className="info-box">
            <p className="title"> {t("history")}</p>
            <p className="description">
              <strong> {t("telmetalAboutUsEN")}</strong>
              {t("aboutUsParagraphOneEN")}
              {t("aboutUsParagraphOneTR")}
              <strong> {t("telmetalAboutUsTR")}</strong>
              {t("aboutUsParagraphTwoTR")}
              <strong> {t("telmetalAboutUsTR")}</strong>
              <strong> {t("telmetalAboutUsEN")}</strong>
              {t("aboutUsParagraphThreeTR")}
              {t("aboutUsParagraphTwoEN")}
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="container sub-description-container">
            <hr />
            <p className="sub-description">
              {t("aboutUsDescriptionOne")}
              <br className="d-none d-lg-block" />
              {t("aboutUsDescriptionTwo")}
            </p>
            <hr />
          </div>
        </Fade>

        <Fade bottom>
          <div className="description-area area-one">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <p className="title">{t("values")}</p>
                  <p className="description">
                    <strong> {t("telmetal")}</strong>
                    {t("valuesParagraph")}
                  </p>
                  <p className="description">
                    <strong>Telmetal</strong>
                    {t("valuesParagraphTwo")}
                  </p>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    className="w-100"
                    src="/assets/images/about-us/image-3.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none mt-5">
              <img
                className="w-100"
                src="/assets/images/about-us/image-3.jpg"
                alt=""
              />
            </div>
          </div>
        </Fade>
        <div className="gray-area">
          <div className=" m-0 h-100 align-items-center slider-text-container">
            <div className="container d-block d-lg-none ">
              <div className="row">
                <div className="col-12 d-flex flex-column ml-md-3">
                  <p className="title">{t("qualityPolicyTitle")}</p>
                  <p className="description">{t("qualityPolicyDescription")}</p>
                </div>
              </div>
            </div>
            <div className="row m-0 slider-area align-items-center">
              <div className="col-sm-12 col-md-10 col-lg-8 col-xl-7 p-0">
                <Slider {...settings}>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider1")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider2")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider3")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider4")}</p>
                    </div>
                  </div>
                  <div style={{ margin: "0 40px" }}>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider5")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider6")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider7")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="slider-card">
                      <p>{t("qualityPolicySlider8")}</p>
                    </div>
                  </div>
                </Slider>
              </div>
              <div className="col-3 col-lg-3 col-xl-3 d-none d-lg-flex flex-column justify-content-center ml-5">
                <p className="title">{t("qualityPolicyTitle")}</p>
                <p className="description">{t("qualityPolicyDescription")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="description-area">
          <div className="d-block d-lg-none mb-4">
            <img
              className="w-100"
              src="/assets/images/about-us/image-1.jpg"
              alt=""
            />
          </div>
          <Fade bottom>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <p className="title">{t("healtSafetyPolicyTitle")}</p>
                  <p className="description">
                    {t("healtSafetyPolicyDescription1")}
                    <br />
                    {t("healtSafetyPolicyDescription2")}
                    <br />
                    {t("healtSafetyPolicyDescription3")}
                    <br />
                    {t("healtSafetyPolicyDescription4")}
                    <br />
                    {t("healtSafetyPolicyDescription5")}
                    <br />
                    {t("healtSafetyPolicyDescription6")}
                  </p>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    className="w-100"
                    src="/assets/images/about-us/image-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none mb-4  mt-5">
              <img
                className="w-100"
                src="/assets/images/about-us/image-2.jpg"
                alt=""
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className="container">
              <div className="area-margin">
                <div className="row align-items-center">
                  <div className="col-lg-6 d-none d-lg-block">
                    <img
                      className="w-100"
                      src="/assets/images/about-us/image-2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="">
                      <p className="title">{t("environmentalPolicy")}</p>
                      <p className="description">
                        {t("environmentalPolicyDescription")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <Footer isContactPage={false} />
    </div>
  );
};

export default AboutUs;
