import { Button, Drawer, Dropdown, Menu } from "antd";
import "antd/lib/drawer/style/css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import LanguageSelection from "../Language/Language";
import "./Header.scss";

const { SubMenu } = Menu;

const Header = ({ colorfulLinkItem = true, isTransparentHeader = true }) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [className, setClassName] = useState("");
  const [isProductPage, setIsProductPage] = useState(false);
  const [colorfulLink, setColorfulLink] = useState(false);
  const [isPageScrolling, setIsPageScrolling] = useState(true);

  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const handleHamburgerIconOnClick = () => {
    setIsMobileMenuVisible(true);
  };

  const handleHamburgerIconOnCloseClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };
  const history = useHistory();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    history.push("/" + i18n.language);
  };
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (className === "") {
        setClassName("header-background");
        setColorfulLink(true);
        setIsPageScrolling(false);
      }
    } else {
      setClassName("");
      setColorfulLink(false);
      setIsPageScrolling(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const currentLocation = window.location.pathname;
    if (
      currentLocation === "/urunler-celik" ||
      currentLocation === "/urunler-paslanmaz" ||
      currentLocation === "/urunler-paketleme"
    ) {
      setIsProductPage(true);
    }
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleMenuClick = () => {};

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <NavLink
          to={`/${i18n.language}/${t("productsSteel")}`}
          exact
          activeClassName="active"
        >
          <span className={` ${colorfulLinkItem ? "colorful-link" : ""}`}>
            {t("steelQualitiesLowerCase")}
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink
          to={`/${i18n.language}/${t("productsStainless")}`}
          exact
          activeClassName="active"
        >
          <span className={` ${colorfulLinkItem ? "colorful-link" : ""}`}>
            {t("stainlessQualitiesLowerCase")}
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          to={`/${i18n.language}/${t("productsPackaging")}`}
          exact
          activeClassName="active"
        >
          <span className={` ${colorfulLinkItem ? "colorful-link" : ""}`}>
            {t("ourPackagingFacilitiesLowerCase")}
          </span>
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <div id="header" className={className}>
      <div className="custom-container d-none d-lg-block mx-auto">
        <div className="row align-items-center justify-content-around">
          <div className="col-md-4 col-lg-3">
            <div className="header-logo d-flex">
              <NavLink to={`/${i18n.language}`} exact activeClassName="active">
                <div>
                  {!isPageScrolling && (
                    <img
                      className="w-100"
                      src="/assets/images/home/logo.svg"
                      alt=""
                    />
                  )}
                  {isPageScrolling && (
                    <img
                      className="w-100"
                      src="/assets/images/home/logo-white.svg"
                      alt=""
                    />
                  )}
                </div>
              </NavLink>
            </div>
          </div>
          <div className="col-md-8 col-lg-7 col-lg-6">
            <ul className="header-list d-flex align-items-center mb-0">
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("aboutUsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                    style={colorfulLink ? { color: "#000000" } : {}}
                  >
                    {t("aboutUsUpperCase")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("technologyUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                    style={colorfulLink ? { color: "#000000" } : {}}
                  >
                    {t("technologyUsUpperCase")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <Dropdown
                  overlay={menu}
                  className={` ${isProductPage ? "active" : ""}`}
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                    style={colorfulLink ? { color: "#000000" } : {}}
                  >
                    {t("products")}
                  </span>
                </Dropdown>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("servicesUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                    style={colorfulLink ? { color: "#000000" } : {}}
                  >
                    {t("servicesUpperCase")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("contactUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                    style={
                      colorfulLink ? { color: "#000000" } : { color: "#ffffff" }
                    }
                  >
                    {t("contactUpperCase")}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center language-container">
            <button
              className={`language-button ${
                isPageScrolling ? "colorful-link-lang" : "not-colorful-link "
              }`}
              type="button"
              onClick={() => changeLanguage("en")}
            >
              {i18n.language === "en" ? (
                <strong
                  className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                >
                  EN
                </strong>
              ) : (
                <span className={` ${colorfulLinkItem ? "colorful-link" : ""}`}>
                  EN
                </span>
              )}
            </button>
            {colorfulLinkItem ? (
              <div className="colorful-link-line"></div>
            ) : (
              <div
                className={` ${
                  isPageScrolling ? "language-line" : "colorful-link-line"
                }`}
              ></div>
            )}
            <button
              className={`language-button ${
                isPageScrolling ? "colorful-link-lang" : "no  t-colorful-link "
              }`}
              type="button"
              onClick={() => changeLanguage("tr")}
            >
              {i18n.language === "tr" ? (
                <strong
                  className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                >
                  TR
                </strong>
              ) : (
                <span className={` ${colorfulLinkItem ? "colorful-link" : ""}`}>
                  TR
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none">
        <div
          className="mobile-header px-3"
          style={
            isTransparentHeader
              ? { background: "transparent" }
              : { background: "white" }
          }
        >
          <div className="header-logo d-flex">
            <NavLink to={`/${i18n.language}`} exact activeClassName="active">
              <div>
                {!isPageScrolling && (
                  <img
                    style={{ width: "149px" }}
                    src="/assets/images/home/logo.svg"
                    alt=""
                  />
                )}
                {isPageScrolling && (
                  <img
                    style={{ width: "149px" }}
                    src="/assets/images/home/logo-white.svg"
                    alt=""
                  />
                )}
              </div>
            </NavLink>
          </div>
          <Button
            type="primary"
            className="p-0"
            onClick={handleHamburgerIconOnClick}
          >
            {isTransparentHeader ? (
              <img src="/assets/images/home/hamburger-icon-white.svg" alt="" />
            ) : (
              <img src="/assets/images/home/hamburger-icon-black.svg" alt="" />
            )}
          </Button>
          {className === "header-background" && isTransparentHeader && (
            <Button
              className="p-0"
              type="primary"
              onClick={handleHamburgerIconOnClick}
            >
              <img src="/assets/images/home/hamburger-icon-black.svg" alt="" />
            </Button>
          )}
          <Drawer
            placement="right"
            closable={false}
            onClose={handleHamburgerIconOnCloseClick}
            visible={isMobileMenuVisible}
          >
            <div className="menu-header">
              <LanguageSelection />
              <Button onClick={handleHamburgerIconOnCloseClick}>
                <img src="/assets/images/home/close-icon.svg" alt="" />
              </Button>
            </div>
            <ul className="header-list d-flex justify-content-between flex-column drawer-mobile">
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("aboutUsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                  >
                    {t("aboutUs")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("technologyUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                  >
                    {t("technology")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <div>
                  <Menu
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={[""]}
                    mode="inline"
                  >
                    <SubMenu title={t("productsLowerCase")}>
                      <Menu.Item>
                        <NavLink
                          to={`/${i18n.language}/${t("productsSteel")}`}
                          exact
                          activeClassName="active"
                        >
                          <span
                            className={` ${
                              colorfulLinkItem ? "colorful-link" : ""
                            }`}
                          >
                            <span className="mobile-menu-item">
                              {t("steelQualitiesLowerCase")}
                            </span>
                          </span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        <NavLink
                          to={`/${i18n.language}/${t("productsStainless")}`}
                          exact
                          activeClassName="active"
                        >
                          <span
                            className={` ${
                              colorfulLinkItem ? "colorful-link" : ""
                            }`}
                          >
                            <span className="mobile-menu-item">
                              {t("stainlessQualitiesLowerCase")}
                            </span>
                          </span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        <NavLink
                          to={`/${i18n.language}/${t("productsPackaging")}`}
                          exact
                          activeClassName="active"
                        >
                          <span
                            className={` ${
                              colorfulLinkItem ? "colorful-link" : ""
                            }`}
                          >
                            <span className="mobile-menu-item">
                              {t("ourPackagingFacilitiesLowerCase")}
                            </span>
                          </span>
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                </div>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("servicesUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                  >
                    {t("servicesLowerCase")}
                  </span>
                </NavLink>
              </li>
              <li className="header-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("contactUrl")}`}
                  exact
                  activeClassName="active"
                >
                  <span
                    className={` ${colorfulLinkItem ? "colorful-link" : ""}`}
                  >
                    {t("contact")}
                  </span>
                </NavLink>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
};
export default Header;
