import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Services.scss";
const Fade = require("react-reveal/Fade");

const Services = () => {
  const { t } = useTranslation();

  return (
    <div id="services">
      <Header isTransparentHeader={false} colorfulLinkItem={true} />
      <div className="services-container">
        <div className="container">
          <Fade bottom>
            <div className="row margin">
              <div className="col-12 mb-4 p-0 d-block d-md-none">
                <div>
                  <img
                    className="w-100"
                    src="/assets/images/services/mobile-image-1.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="title">{t("wireDrawing")}</div>
                <div className="description">
                  <strong>{t("telmetal")}</strong>
                  {t("wireDrawingDescription")}
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-5 d-none d-md-block">
                <div>
                  <img
                    className="w-100 image-desktop"
                    src="/assets/images/services/image-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="row margin">
              <div className="col-12 mb-4 p-0 d-block d-md-none mt-4">
                <div>
                  <img
                    className="w-100"
                    src="/assets/images/services/mobile-image-2.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="d-none d-md-block col-5">
                <div>
                  <img
                    className="w-100 image-desktop"
                    src="/assets/images/services/image-2.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="title">{t("surfaceTreatment")}</div>
                <div className="description">
                  {t("surfaceTreatmentDescription")}
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="row margin">
              <div className="col-12 mb-4 p-0 d-block d-md-none mt-4">
                <div>
                  <img
                    className="w-100"
                    src="/assets/images/services/mobile-image-3.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="title">{t("heatTreatment")}</div>
                <div className="description">
                  {t("heatTreatmentDescription")}
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-5 d-none d-md-block">
                <div>
                  <img
                    className="w-100 image-desktop"
                    src="/assets/images/services/image-3.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="row margin">
              <div className="col-12 mb-4 p-0 d-block d-md-none mt-4">
                <div>
                  <img
                    className="w-100"
                    src="/assets/images/services/mobile-image-4.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-5 d-none d-md-block">
                <div>
                  <img
                    className="w-100 image-desktop"
                    src="/assets/images/services/image-4.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="title">{t("logistics")}</div>
                <div className="description">
                  <strong>{t("telmetal")}</strong>
                  {t("logisticsDescription")}
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="row last-margin">
              <div className="col-12 mb-4 p-0 d-block d-md-none mt-4">
                <div>
                  <img
                    className="w-100"
                    src="/assets/images/services/mobile-image-5.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="title">{t("technicalSalesConsultancy")}</div>
                <div className="description">
                  <strong>{t("telmetal")}</strong>
                  {t("technicalSalesConsultancyDescription")}
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-5 d-none d-md-block">
                <div>
                  <img
                    className="w-100 image-desktop"
                    src="/assets/images/services/image-5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <Footer isContactPage={false} />
    </div>
  );
};
export default Services;
