import React from "react";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-parallax";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./home.scss";

const Fade = require("react-reveal/Fade");

const HomePage = () => {
  const { t, i18n } = useTranslation();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <div id="home-page">
      <Header isTransparentHeader={true} colorfulLinkItem={false} />
      <div className="banner-container">
        <Parallax
          className="react-parallax-one d-none d-md-block"
          bgImage={"/assets/images/home/banner.jpg"}
          strength={500}
        >
          <div>
            <div className="info-area">
              <div className="custom-container-info">
                <div className="row align-items-center">
                  <Fade bottom>
                    <div className="col-7">
                      <p className="title">{t("provideContinuity")}</p>
                      <p className="subtitle">{t("innovativeSolutions")}</p>
                      <p className="description"> {t("buildWith")}</p>
                      <p className="description-bold">{t("companyProducts")}</p>
                    </div>
                    <div className="col-5 text-right">
                      <div className="margin-area">
                        <p className="bold-text">31 {t("year")}</p>
                        <div className="line"></div>
                        <p className="light-text">{t("experience")}</p>
                      </div>
                      <div className="margin-area">
                        <p className="bold-text">35.000 t</p>
                        <div className="line"></div>
                        <p className="light-text">{t("productionCapacity")}</p>
                      </div>
                      <div>
                        <p className="bold-text">6 {t("industries")}</p>
                        <div className="line"></div>
                        <p className="mb-1 light-text">{t("automotive")}</p>
                        <p className="mb-1 light-text">
                          {t("automotiveSubsidiaryIndustry")}
                        </p>
                        <p className="mb-1 light-text">{t("construction")}</p>
                        <p className="mb-1 light-text">{t("whiteGoods")}</p>
                        <p className="mb-1 light-text">
                          {t("electricElectronic")}
                        </p>
                        <p className="mb-1 light-text">{t("furniture")}</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax
          className="react-parallax-one d-block d-md-none"
          bgImage={"/assets/images/home/banner-mobile.jpg"}
          strength={500}
        >
          <div>
            <div className="info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="offset-md-1"></div>
                  <Fade bottom>
                    <div className="col-sm-12 col-md-6">
                      <div className="mb-3">
                        <span className="title mr-2">
                          {t("provideContinuity")}
                        </span>
                        <span className="subtitle">
                          {t("innovativeSolutions")}
                        </span>
                      </div>
                      <p className="description mb-3">{t("buildWith")}</p>
                      <p className="description-bold">{t("companyProducts")}</p>
                    </div>
                  </Fade>
                  <div className="col-sm-12 col-md-5 right-side">
                    <div className="margin-area mt-4">
                      <p className="bold-text">31 {t("year")}</p>
                      <div className="line"></div>
                      <p className="light-text">{t("experience")}</p>
                    </div>
                    <div className="margin-area">
                      <p className="bold-text">35.000 t</p>
                      <div className="line"></div>
                      <p className="light-text">{t("productionCapacity")}</p>
                    </div>
                    <div>
                      <p className="bold-text">6 {t("industries")}</p>
                      <div className="line"></div>
                      <p className="mb-1 light-text">{t("automotive")}</p>
                      <p className="mb-1 light-text">
                        {t("automotiveSubsidiaryIndustry")}
                      </p>
                      <p className="mb-1 light-text">{t("construction")}</p>
                      <p className="mb-1 light-text">{t("whiteGoods")}</p>
                      <p className="mb-1 light-text">
                        {t("electricElectronic")}
                      </p>
                      <p className="mb-1 light-text">{t("furniture")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <div className="banner-container-two">
        <Parallax
          className="react-parallax-two"
          bgImage={"/assets/images/home/banner-2.png"}
          strength={500}
        >
          <div className="custom-container d-none d-md-block">
            <div className="info-area-two">
              <div style={{ width: "68vw", margin: "auto" }}>
                <div className="d-flex align-items-center flex-column">
                  <Fade bottom>
                    <div className="w-100">
                      <p className="title title-products">{t("ourProducts")}</p>
                      <div className="d-flex justify-content-between margin products-type">
                        <div className="d-flex align-items-center documents-link-container ">
                          <NavLink
                            to={`/${i18n.language}/${t("productsSteel")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span>{t("steelQualities")}</span>
                          </NavLink>
                        </div>
                        <div className="d-flex align-items-center documents-link-container ">
                          <NavLink
                            to={`/${i18n.language}/${t("productsStainless")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span>{t("stainlessQualities")}</span>
                          </NavLink>
                        </div>
                        <div className="d-flex align-items-center documents-link-container ">
                          <NavLink
                            to={`/${i18n.language}/${t("productsPackaging")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span>{t("ourPackagingFacilities")}</span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title title-services">{t("services")}</p>
                        <div className="d-flex align-items-center documents-link-container title-services">
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="more-info-link">
                              {t("moreInformation")}
                            </span>
                          </NavLink>
                        </div>
                      </div>

                      <Slider {...settings}>
                        <div>
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              src={
                                i18n.language === "en"
                                  ? "/assets/images/home/hizmetler_web_en_1.png"
                                  : "/assets/images/home/hizmetler_web_tr_1.png"
                              }
                              alt=""
                              style={{ width: "93%", margin: "auto" }}
                            />
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              src={
                                i18n.language === "en"
                                  ? "/assets/images/home/hizmetler_web_en_2.png"
                                  : "/assets/images/home/hizmetler_web_tr_2.png"
                              }
                              alt=""
                              style={{ width: "93%", margin: "auto" }}
                            />
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              src={
                                i18n.language === "en"
                                  ? "/assets/images/home/hizmetler_web_en_3.png"
                                  : "/assets/images/home/hizmetler_web_tr_3.png"
                              }
                              alt=""
                              style={{ width: "93%", margin: "auto" }}
                            />
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              src={
                                i18n.language === "en"
                                  ? "/assets/images/home/hizmetler_web_en_4.png"
                                  : "/assets/images/home/hizmetler_web_tr_4.png"
                              }
                              alt=""
                              style={{ width: "93%", margin: "auto" }}
                            />
                          </NavLink>
                        </div>
                        <div style={{ margin: "0 40px" }}>
                          <NavLink
                            to={`/${i18n.language}/${t("servicesUrl")}`}
                            exact
                            className="go-to-page-link"
                          >
                            <img
                              src={
                                i18n.language === "en"
                                  ? "/assets/images/home/hizmetler_web_en_5.png"
                                  : "/assets/images/home/hizmetler_web_tr_5.png"
                              }
                              alt=""
                              style={{ width: "93%", margin: "auto" }}
                            />
                          </NavLink>
                        </div>
                      </Slider>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="row w-100 m-0">
                      <div className="col-7 p-0">
                        <p className="title title-technology">
                          {t("technology")}
                        </p>
                      </div>
                      <div className="d-block d-md-none d-lg-block offset-1 col-4 p-0">
                        <p className="title title-news">
                          {t("newsFromTelmetal")}
                        </p>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="row m-0 w-100">
                      <div className="col-md-12 col-lg-7 p-0">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center flex-column technology-links">
                            <NavLink
                              to={`/${i18n.language}/${t("technologyUrl")}`}
                              exact
                            >
                              <img
                                className="image-technology"
                                src="/assets/images/home/arge.jpg"
                                alt=""
                              />
                              <div className="d-flex align-items-center  justify-content-center documents-link-container ">
                                <div className="go-to-page-link">
                                  <img
                                    className="arrow-black"
                                    src="/assets/images/footer/arrow-black.svg"
                                    alt=""
                                  />
                                  <span>{t("R&D")}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                          <div className="d-flex align-items-center flex-column technology-links">
                            <NavLink
                              to={`/${i18n.language}/${t("technologyUrl")}`}
                              exact
                            >
                              <img
                                className="image-technology"
                                src="/assets/images/home/uretim.jpg"
                                alt=""
                              />
                              <div className="d-flex align-items-center justify-content-center documents-link-container ">
                                <div className="go-to-page-link">
                                  <img
                                    className="arrow-black"
                                    src="/assets/images/footer/arrow-black.svg"
                                    alt=""
                                  />
                                  <span>{t("production")}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                          <div className="d-flex align-items-center flex-column technology-links">
                            <NavLink
                              to={`/${i18n.language}/${t("technologyUrl")}`}
                              exact
                            >
                              <img
                                className="image-technology"
                                src="/assets/images/home/kalite.jpg"
                                alt=""
                              />
                              <div className="d-flex align-items-center justify-content-center documents-link-container ">
                                <div className="go-to-page-link">
                                  <img
                                    className="arrow-black"
                                    src="/assets/images/footer/arrow-black.svg"
                                    alt=""
                                  />
                                  <span>{t("quality")}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="offset-lg-1 col-md-12 col-lg-4 p-0">
                        <div className="d-none d-md-block d-lg-none col-md-12 col-lg-4 p-0">
                          <p className="title title-news">
                            {t("newsFromTelmetal")}
                          </p>
                        </div>
                        <div>
                          <p className="text-news mb-5">{t("newsOne")}</p>
                          <p className="text-news  mb-5">{t("newsTwo")}</p>
                          <p className="text-news">{t("newsThree")}</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-container-mobile d-block d-md-none">
            <div className="container">
              <Fade bottom>
                <div className="products-card">
                  <p className="title m-0"> {t("ourProducts")}</p>
                  <div className="d-flex align-items-center documents-link-container ">
                    <NavLink
                      to={`/${i18n.language}/${t("productsSteel")}`}
                      exact
                      className="go-to-page-link"
                    >
                      <img
                        className="arrow-black mr-2"
                        src="/assets/images/footer/arrow-black.svg"
                        alt=""
                      />
                      <span className="subtitle">{t("steelQualities")}</span>
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center documents-link-container ">
                    <NavLink
                      to={`/${i18n.language}/${t("productsStainless")}`}
                      exact
                      className="go-to-page-link"
                    >
                      <img
                        className="arrow-black mr-2"
                        src="/assets/images/footer/arrow-black.svg"
                        alt=""
                      />
                      <span className="subtitle">
                        {t("stainlessQualities")}
                      </span>
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center documents-link-container ">
                    <NavLink
                      to={`/${i18n.language}/${t("productsPackaging")}`}
                      exact
                      className="go-to-page-link"
                    >
                      <img
                        className="arrow-black mr-2"
                        src="/assets/images/footer/arrow-black.svg"
                        alt=""
                      />
                      <span className="subtitle">
                        {t("ourPackagingFacilities")}
                      </span>
                    </NavLink>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Parallax>
      </div>
      <div className="d-block d-md-none mobile-info">
        <div className="container mb-4">
          <div className="d-flex align-items-center justify-content-between">
            <p className="title m-0"> {t("services")}</p>
            <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
              <p className="more-info-link m-0">{t("moreInformation")}</p>
            </NavLink>
          </div>
        </div>
        <div className="d-block d-md-none">
          <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
            <img
              className="w-100 mb-3"
              src={
                i18n.language === "en"
                  ? "/assets/images/home/hizmetler_mobil_en_1.png"
                  : "/assets/images/home/hizmetler_mobil_tr_1.png"
              }
              alt=""
            />
          </NavLink>
          <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
            <img
              className="w-100 mb-3"
              src={
                i18n.language === "en"
                  ? "/assets/images/home/hizmetler_mobil_en_2.png"
                  : "/assets/images/home/hizmetler_mobil_tr_2.png"
              }
              alt=""
            />
          </NavLink>
          <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
            <img
              className="w-100 mb-3"
              src={
                i18n.language === "en"
                  ? "/assets/images/home/hizmetler_mobil_en_3.png"
                  : "/assets/images/home/hizmetler_mobil_tr_3.png"
              }
              alt=""
            />
          </NavLink>
          <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
            <img
              className="w-100 mb-3"
              src={
                i18n.language === "en"
                  ? "/assets/images/home/hizmetler_mobil_en_4.png"
                  : "/assets/images/home/hizmetler_mobil_tr_4.png"
              }
              alt=""
            />
          </NavLink>
          <NavLink to={`/${i18n.language}/${t("servicesUrl")}`} exact>
            <img
              className="w-100 mb-3"
              src={
                i18n.language === "en"
                  ? "/assets/images/home/hizmetler_mobil_en_5.png"
                  : "/assets/images/home/hizmetler_mobil_tr_5.png"
              }
              alt=""
            />
          </NavLink>
        </div>
        <div className="container mt-5">
          <p className="title"> {t("technology")}</p>
          <div className="d-flex align-items-center justify-content-between mobile-technology flex-column">
            <NavLink
              to={`/${i18n.language}/${t("technologyUrl")}`}
              exact
              className="d-flex align-items-center flex-column mb-5"
            >
              <img
                className="image-technology mb-3"
                src="/assets/images/home/arge.jpg"
                alt=""
              />
              <div className="d-flex align-items-center documents-link-container ">
                <div className="go-to-page-link">
                  <img
                    className="arrow-black"
                    src="/assets/images/footer/arrow-black.svg"
                    alt=""
                  />
                  <span className="ml-2">{t("R&D")}</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to={`/${i18n.language}/${t("technologyUrl")}`}
              exact
              className="d-flex align-items-center flex-column mb-5"
            >
              <img
                className="image-technology mb-3"
                src="/assets/images/home/uretim.jpg"
                alt=""
              />
              <div className="d-flex align-items-center documents-link-container ">
                <div className="go-to-page-link">
                  <img
                    className="arrow-black"
                    src="/assets/images/footer/arrow-black.svg"
                    alt=""
                  />
                  <span className="ml-2">{t("production")}</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to={`/${i18n.language}/${t("technologyUrl")}`}
              exact
              className="d-flex align-items-center flex-column mb-5"
            >
              <img
                className="image-technology mb-3"
                src="/assets/images/home/kalite.jpg"
                alt=""
              />
              <div className="d-flex align-items-center documents-link-container ">
                <div className="go-to-page-link">
                  <img
                    className="arrow-black"
                    src="/assets/images/footer/arrow-black.svg"
                    alt=""
                  />
                  <span className="ml-2">{t("quality")}</span>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="container">
          <p className="title">{t("newsFromTelmetal")}</p>
          <div>
            <p className="text-news mb-4">{t("newsOne")}</p>
            <p className="text-news  mb-4">{t("newsTwo")}</p>
            <p className="text-news mb-5">{t("newsThree")}</p>
          </div>
        </div>
      </div>
      <Footer isContactPage={false} />
    </div>
  );
};

export default HomePage;
