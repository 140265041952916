import "antd/lib/drawer/style/css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./Language.scss";

const LanguageSelection = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    history.push("/" + i18n.language);
  };

  return (
    <div className="d-flex align-items-center">
      <button
        className="language-button"
        type="button"
        onClick={() => changeLanguage("en")}
      >
        {i18n.language === "en" ? <strong>EN</strong> : <span>EN</span>}
      </button>
      <div className="language-line"></div>
      <button
        className="language-button"
        type="button"
        onClick={() => changeLanguage("tr")}
      >
        {i18n.language === "tr" ? <strong>TR</strong> : <span>TR</span>}
      </button>
    </div>
  );
};

export default LanguageSelection;
