import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import AboutUs from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import Documents from "./pages/Documents/Documents";
import HomePage from "./pages/home/home";
import ProductsPackaging from "./pages/ProductsPackaging/ProductsPackaging";
import ProductsStainless from "./pages/ProductsStainless/ProductsStainless";
import ProductsSteel from "./pages/ProductsSteel/ProductsSteel";
import Services from "./pages/Services/Services";
import Technology from "./pages/Technology/Technology";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/en" component={HomePage} />
        <Route exact path="/tr" component={HomePage} />
        <Route path="/en/contact" component={ContactPage} />
        <Route exact path="/tr/iletisim" component={ContactPage} />
        <Route exact path="/en/technology" component={Technology} />
        <Route exact path="/tr/teknoloji" component={Technology} />
        <Route exact path="/en/about-us" component={AboutUs} />
        <Route exact path="/tr/hakkimizda" component={AboutUs} />
        <Route
          exact
          path="/en/stainless-qualities"
          component={ProductsStainless}
        />
        <Route
          exact
          path="/tr/paslanmaz-kaliteleri"
          component={ProductsStainless}
        />
        <Route exact path="/en/steel-qualities" component={ProductsSteel} />
        <Route exact path="/tr/celik-kaliteleri" component={ProductsSteel} />
        <Route
          exact
          path="/tr/paketleme-olanaklari"
          component={ProductsPackaging}
        />
        <Route
          exact
          path="/en/packaging-facilities"
          component={ProductsPackaging}
        />
        <Route exact path="/tr/dokumanlar" component={Documents} />
        <Route exact path="/en/documents" component={Documents} />
        <Route exact path="/en/services" component={Services} />
        <Route exact path="/tr/hizmetler" component={Services} />
        <Route path="*">
          <Redirect to="/tr" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
export default App;
