import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Technology.scss";
const Fade = require("react-reveal/Fade");

const Technology = () => {
const { t, i18n } = useTranslation();

    return (
      <div id="technology">
        <Header isTransparentHeader={false} colorfulLinkItem={true} />
        <div className="technology-container">
          <div className="container">
            <Fade bottom>
              <div className="margins">
                <div className="row align-items-center first-area">
                  <div className="col-sm-12 col-md-6">
                    <p className="title">{t("R&DLowerCase")}</p>
                    <p className="description">
                      {t("within")} <strong>{t("telmetal")}</strong>
                      {t("r&dDescription")}
                    </p>
                    <p className="description">{t("r&dDescriptionTwo")}</p>
                  </div>
                  <div className="col-md-6 p-sm-0 p-md-3">
                    <img
                      className="w-100"
                      src="/assets/images/technology/technology-image1.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="margins">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <img
                      className="w-100"
                      src="/assets/images/technology/technology-image2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <p className="title"> {t("productionLowerCase")}</p>
                    <p className="description">
                      <strong> {t("telmetal")}</strong>
                      {t("r&dDescriptionThree")}
                    </p>
                    <p className="description">{t("r&dDescriptionFour")}</p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="margins">
                <div className="row align-items-center third-area">
                  <div className="col-sm-12 col-md-6">
                    <p className="title">{t("qualityLowerCase")}</p>
                    <p className="subtitle">{t("qualityTitle")}</p>
                    <p className="description">
                      {t("in")}
                      <strong>{t("telmetal")}</strong>
                      {t("r&dDescriptionFive")}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <img
                      className="w-100"
                      src="/assets/images/technology/technology-image3.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="margins">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <img
                      className="w-100"
                      src="/assets/images/technology/technology-image4.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <p className="subtitle mt-4">{t("qualityTitle")}</p>
                    <p className="description">{t("r&dDescriptionSix")}</p>
                    <div className="d-flex align-items-center mt-5">
                      <NavLink
                        to={`/${i18n.language}/${t("documentsUrl")}`}
                        exact
                        className="d-flex align-items-center"
                      >
                        <img
                          className="arrow-black"
                          src="/assets/images/footer/arrow-black.svg"
                          alt=""
                        />
                        <span className="documents-link ml-3">
                          {t("documents")}
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <Footer isContactPage={false} />
      </div>
    );
};

export default Technology;