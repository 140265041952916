import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./ProductsSteel.scss";


const ProductsSteel = () => {
const { t } = useTranslation();

  return (
    <div id="products-steel">
      <Header
        isTransparentHeader={false}
        colorfulLinkItem={true}
      />
      <div className="products-container">
        <div className="container">
          <div>
            <div>
              <p className="title"> {t("steelQualitiesLowerCase")}</p>
            </div>
            <div className="download-products">
              <img
                src="/assets/images/products/product-list2.jpg"
                alt=""
                className="d-none d-md-block product-desktop"
              />
              <img
                src="/assets/images/products/product-list2-mobile.jpg"
                alt=""
                className="w-100 d-block d-md-none"
              />
              <div
                className="d-flex align-items-center documents-link-container "
                onClick={(event) => {
                  event.preventDefault();
                  window.open("/assets/pdf-files/celik kaliteleri.pdf");
                }}
              >
                <img
                  className="arrow-black"
                  src="/assets/images/footer/arrow-black.svg"
                  alt=""
                />
                <span className="documents-link ml-3">
                  {t("downloadPDF")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isContactPage={false} />
    </div>
  );
};

export default ProductsSteel;