import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Documents.scss";

const Documents = ( ) => {
  const { t } = useTranslation();

  return (
    <div id="documents">
      <Header
        isTransparentHeader={true}
        colorfulLinkItem={false}
      />
      <div>
        <div
          className="banner-container d-none d-md-block"
          style={{
            background: "url(/assets/images/documents/documents-banner.jpg)",
          }}
        ></div>
        <div
          className="banner-container d-md-none"
          style={{
            background:
              "url(/assets/images/about-us/about-us-banner-mobile.jpg)",
          }}
        ></div>
      </div>
      <div className="container custom-height-container">
        <div className="row">
          <div className="col-sm-12 col-md-10">
            <div className="documents-area">
              <div className="row justify-content-center m-0">
                <div className="col-12 p-0">
                  <p className="title">{t("documents")}</p>
                </div>
                <div className="col-sm-12 col-md-10 p-0">
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">ISO 45001:2018</p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate1.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open("/assets/pdf-files/ISO 45001.pdf");
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">ISO 14001:2015</p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate2.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open("/assets/pdf-files/ISO 14001.pdf");
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">ISO 9001:2015</p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate3.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open("/assets/pdf-files/ISO 9001.pdf");
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center margin">
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">
                          {t("steelQualitiesLowerCase")}
                        </p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate4.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open(
                                "/assets/pdf-files/celik kaliteleri.pdf"
                              );
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">
                          {t("stainlessQualitiesLowerCase")}
                        </p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate5.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open(
                                "/assets/pdf-files/paslanmaz kaliteleri.pdf"
                              );
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="item">
                        <p className="subtitle">
                          {t("ourPackagingFacilitiesLowerCase")}
                        </p>
                        <div className="download-link">
                          <img
                            src="/assets/images/documents/certificate6.jpg"
                            alt=""
                            className="certificate"
                          />
                          <div
                            className="d-flex align-items-center documents-link-container "
                            onClick={(event) => {
                              event.preventDefault();
                              window.open(
                                "/assets/pdf-files/paketleme olanaklari.pdf"
                              );
                            }}
                          >
                            <img
                              className="arrow-black"
                              src="/assets/images/footer/arrow-black.svg"
                              alt=""
                            />
                            <span className="documents-link ml-3">
                              {t("downloadPDF")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isContactPage={false} />
    </div>
  );
};
export default Documents;
