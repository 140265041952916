import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Footer.scss";

const Footer = ({isContactPage = false}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      id="footer"
      style={
        isContactPage
          ? { background: "#f7f7f7", paddingTop: "60px" }
          : { background: "#ffffff" }
      }
    >
      {!isContactPage && (
        <div>
          <div className="question-area d-none d-md-flex align-items-center justify-content-center">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="text-bold">{t("haveQuestion")}</p>
                  <p className="text-light">{t("promptlyResponse")}</p>
                </div>
                <div>
                  <p className="contact">+90 262 759 18 00</p>
                  <p className="contact">info@telmetal.com</p>
                </div>
                <div>
                  <p className="m-0 contact-form-link">{t("or")}</p>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    to={`/${i18n.language}/${t("contactUrl")}`}
                    exact
                    activeClassName="active"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="/assets/images/footer/arrow-black.svg"
                      className="arrow-black mr-3"
                      alt=""
                    />
                    <p className="contact-form-link m-0">{t("contactForm")}</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-md-none">
            <div className="question-area">
              <div className="container h-100 d-flex flex-column first">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="text-bold">{t("haveQuestion")}</p>
                    <p className="text-light">{t("promptlyResponse")}</p>
                  </div>
                  <div>
                    <p className="contact">+90 262 759 18 00</p>
                    <p className="contact">info@telmetal.com</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <NavLink
                    to={`/${i18n.language}/${t("contactUrl")}`}
                    exact
                    activeClassName="active"
                    className="d-flex align-items-center"
                  >
                    <img
                      src="/assets/images/footer/arrow-black.svg"
                      className="arrow-black mr-3"
                      alt=""
                    />
                    <p className="contact-form-link m-0">{t("contactForm")}</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="d-none d-md-flex mt-5">
          <div className="logo-container">
            <div className="d-flex align-items-start flex-column">
              <NavLink to={`/${i18n.language}`} exact activeClassName="active">
                <img
                  src="/assets/images/footer/footer-logo.svg"
                  className="mb-4"
                  alt=""
                />
              </NavLink>
              <div className="social-media-links d-flex">
                <div className="mr-4">
                  <img src="/assets/images/footer/instagram.svg" alt="" />
                </div>
                <div className="mr-4">
                  <img src="/assets/images/footer/youtube.svg" alt="" />
                </div>
                <div>
                  <a href="https://www.linkedin.com/company/telmetal-a.%C5%9F./">
                    <img src="/assets/images/footer/linkedin.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <ul className="footer-list first-list p-0">
              <li className="footer-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("aboutUsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("aboutUs")}
                </NavLink>
              </li>
              <li className="footer-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("technologyUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("technology")}
                </NavLink>
              </li>
              <li className="footer-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("documentsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("documents")}
                </NavLink>
              </li>
              <li className="footer-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("servicesUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("services")}
                </NavLink>
              </li>
            </ul>
            <ul className="footer-list p-0">
              <li className="footer-list-item">
                <NavLink
                  to={`/${i18n.language}/${t("contactUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("contact")}
                </NavLink>
              </li>
              <li className="footer-list-item">
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "/assets/pdf-files/KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI.pdf"
                    );
                  }}
                  className="links"
                >
                  {t("GPDR")}
                </div>
              </li>
              <li className="footer-list-item">
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "/assets/pdf-files/Bilgi Toplumu Hizmetleri.pdf"
                    );
                  }}
                  className="links"
                >
                  {t("ınformationSocietyServices")}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="d-block d-md-none mt-4">
          <div className="d-flex">
            <ul className="footer-list first-list p-0">
              <li className="footer-list-item-mobile">
                <NavLink
                  to={`/${i18n.language}/${t("aboutUsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("aboutUs")}
                </NavLink>
              </li>
              <li className="footer-list-item-mobile">
                <NavLink
                  to={`/${i18n.language}/${t("technologyUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("technology")}
                </NavLink>
              </li>
              <li className="footer-list-item-mobile">
                <NavLink
                  to={`/${i18n.language}/${t("servicesUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("services")}
                </NavLink>
              </li>
              <li className="footer-list-item-mobile">
                <NavLink
                  to={`/${i18n.language}/${t("documentsUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("documents")}
                </NavLink>
              </li>
            </ul>
            <ul className="footer-list p-0">
              <li className="footer-list-item-mobile">
                <NavLink
                  to={`/${i18n.language}/${t("contactUrl")}`}
                  exact
                  activeClassName="active"
                >
                  {t("contact")}
                </NavLink>
              </li>
              <li className="footer-list-item-mobile">
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "/assets/pdf-files/KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI.pdf"
                    );
                  }}
                  className="footer-link"
                >
                  {t("GPDR")}
                </div>
              </li>

              <li className="footer-list-item-mobile">
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "/assets/pdf-files/Bilgi Toplumu Hizmetleri.pdf"
                    );
                  }}
                  className="footer-link"
                >
                  {t("ınformationSocietyServices")}
                </div>
              </li>
            </ul>
          </div>
          <div className="social-media-links-mobile">
            <div className="mr-4">
              <img src="/assets/images/footer/instagram.svg" alt="" />
            </div>
            <div className="mr-4">
              <img src="/assets/images/footer/youtube.svg" alt="" />
            </div>
            <a href="https://www.linkedin.com/company/telmetal-a.%C5%9F./">
              <img src="/assets/images/footer/linkedin.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
